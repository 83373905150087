$primary-color: #13293D;
@import "~animate.css/animate.css";
@import '~loaders.css/src/animations/ball-beat.scss';

.footerNav {
  color: #4d4d4e;
  text-decoration: none;
  a {
    &:hover {
      color: white;

      svg#linkedin {
        color: #0077b5;
      }
      svg#github {
        color: white;
      }
      svg#discord {
        color: #7289da;
      }
    }
  }
}

.headerNav {
  svg {
    color: black;
  }
}

/*
/////////////////////  Loader
*/
i {
  transition: all 0.3s ease-out;
}

.loader-active {
  display: block;
  position: absolute;
  background: #94618E;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  animation: fadeOut 200ms 200ms;
  animation-fill-mode: forwards;
}

.loader-hidden {
  display: none;
}

/*
//////////////////// Logo
*/

.logo-container {
  z-index: 0;
  width: 100%;
  height: auto;
  position: absolute;
  background: #94618E;
  top: 0;
  bottom: 0;
  left: auto;
  opacity: 0;
}

.logo-svg {
  width: 100%;
  height: 100%;
  bottom: 0;
}

.logo-solid {
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  margin: auto;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.strokePath {
  stroke-width: 30;
  stroke: #13293D;
}

/*
//////////////////// CSS Transitions
*/

.logo-enter {
  transform: translate(-100%);
}

.logo-enter-active {
  transform: translate(0);
  transition: transform 1s linear;
}

.logo-enter-done {
  transition: transform 1s linear;
}

.logo-exit {
  transform: translate(0);
}

.logo-exit-active {
  transform: translate(100%);
  transition: transform 1s linear;
}

.logo-exit-done {
  transition: transform 1s linear;
}

.page {
  position: absolute;
  left: 15px;
  right: 15px;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}











