@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  scroll-behavior: smooth;
  background-color: #94618E;
}

body {
  margin: 0;
  font-family: 'Mochiy Pop One', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E0E0E2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loader-hidden {
  display: none;
}
